<template>
    <div class="dialog-inner" style="padding-top:0;">
        <el-form ref="form" :model="form" :rules="rules" label-width="100px" :show-message="true" class="form-col2">
            <div class="form-group-title" style="margin-top:5px;"><h2>用户信息</h2></div>
            <el-row :gutter="20">
                <el-col :span="12">
                    <el-form-item label="所属组织" prop="orgId">
                        <SelectTree 
                            ref="selectTree"
                            placeholder="顶级分类"
                            v-model="form.orgId" 
                            :data="orgTree"
                            :treeProps="{ children: 'children', label: 'name' }"
                            default-expand-all
                            filterable
                            style="width:100%;"
                            size="small">
                        </SelectTree>
                    </el-form-item>
                </el-col>                
                <el-col :span="12">
                    <el-form-item label="工号" prop="userNo">
                        <el-input type="text" v-model="form.userNo" size="small" maxlength="20" @blur="fieldTrim(form, 'userNo')"></el-input>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row :gutter="20">
                <el-col :span="12">
                    <el-form-item label="姓名" prop="userName">
                        <el-input type="text" v-model="form.userName" size="small" maxlength="20" @blur="fieldTrim(form, 'userName')"></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item label="联系电话" prop="tel">
                        <el-input type="text" v-model="form.tel" size="small" maxlength="20" @blur="fieldTrim(form, 'tel')"></el-input>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row :gutter="20">
                <el-col :span="12">
                    <el-form-item label="部门" prop="deptId">
                        <el-select
                            v-model="form.deptId" 
                            placeholder="请选择"
                            size="small"
                            style="width:100%;"
                            @change="handleDeptChange">
                            <el-option
                                v-for="item in deptList"
                                :key="item.id"
                                :label="item.name"
                                :value="item.id">
                            </el-option>
                        </el-select>
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item label="岗位" prop="positionId">
                        <el-select
                            v-model="form.positionId" 
                            placeholder="请选择"
                            size="small"
                            style="width:100%;">
                            <el-option
                                v-for="item in postList"
                                :key="item.id"
                                :label="item.name"
                                :value="item.id">
                            </el-option>
                        </el-select>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row :gutter="20">
                <el-col :span="24">
                    <el-form-item label="角色" prop="roleId">
                        <el-select
                            v-model="roleId" 
                            placeholder="请选择"
                            multiple
                            size="small"
                            style="width:100%;">
                            <el-option
                                v-for="item in roleList"
                                :key="item.id"
                                :label="item.name"
                                :value="item.id">
                            </el-option>
                        </el-select>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row :gutter="20" class="submit-row">
                <el-col :span="24">
                    <el-form-item>
                        <el-button type="primary" @click="handleSubmit" :loading="submitting" size="small">提交</el-button>
                        <el-button @click="resetForm" size="small">重置</el-button>
                    </el-form-item>
                </el-col>
            </el-row>
        </el-form>
    </div>
</template>

<script>
import http from "@/utils/http"
import common from "@/utils/common"
import verify from "@/utils/verify"
import SelectTree from "@/components/SelectTree"

export default {
    name: 'UserAdd',
    components: {
        SelectTree
    },
    data(){
        return {
            deptList: [],
            postList: [],
            roleList: [],
            orgTree: [],
            form: {
                orgId: '',
                userNo: '',
                userName: '',
                tel: '',
                deptId: '',
                positionId: '',
                roleId: '',
                userType: 1,
            },
            roleId: [],
            rules: {
                orgId: [{required: true, message: '所属组织不能为空', trigger: 'change'}],
                userNo: [{required: true, message: '工号不能为空', trigger: 'blur'}],
                userName: [{required: true, message: '姓名不能为空', trigger: 'blur'}], 
                tel: [{required: true, message: '联系电话不能为空', trigger: 'blur'}],
                deptId: [{required: true, message: '部门不能为空', trigger: 'change'}],
                positionId: [{required: true, message: '岗位不能为空', trigger: 'change'}],
            },
            submitting: false
        }
    },
    created() {
        this.loadTree();
        this.loadDept();
        this.loadRole();
    },
    watch: {
        roleId(val) {
            if (!val || val.length == 0) {
                this.form.roleId = '';
                return;
            }

            this.form.roleId = ',' + this.roleId.join(',') + ',';
        },
    },
    methods: {
        ...common,
        ...verify,
        loadTree() {
            let params = { tenantId: 1 };

            http.get('v1/org/orgTree', {
                params: params
            }).then(req => {
                if (req.data.code == http.SUCCESS) {
                    this.orgTree = req.data.data;
                } else {
                    this.showError(req.data.msg);
                }
            }).catch(err => {
                this.httpCatch(err);
            });
        },
        loadDept() {
            let params = { };
            http.get('v1/dept/deptTree', {
                params: params
            }).then(req => {
                if (req.data.code == http.SUCCESS) {
                    this.deptList = req.data.data;
                } else {
                    this.showError(req.data.msg);
                }
            }).catch(err => {
                this.httpCatch(err);
            });
        },
        loadRole() {
            let params = { };
            http.get('v1/role/list', {
                params: params
            }).then(req => {
                if (req.data.code == http.SUCCESS) {
                    this.roleList = req.data.data;
                } else {
                    this.showError(req.data.msg);
                }
            }).catch(err => {
                this.httpCatch(err);
            });
        },
        handleSubmit(){
            this.$refs.form.validate((valid) => {
                if (valid) {
                    this.submitting = true;
                    http.post('v1/user/add', this.form).then(req => {
                        this.submitting = false;
                        if (req.data.code == http.SUCCESS) {
                            this.showSuccess('添加成功', () => {
                                this.$emit('done');
                            });
                        } else {
                            this.showError(req.data.msg);
                        }
                    }).catch(err => {
                        this.tableLoading = false;
                        this.httpCatch(err);
                    });
                } else {
                    return false;
                }
            });
        },
        handleDeptChange(value, clear = true) {
            let dept = this.getListItem(this.deptList, 'id', value);
            if (!dept) {
                this.postList = [];
                return;
            }

            if (clear) {
                this.form.positionId = '';
                this.$nextTick(() => {
                    this.$refs.form.clearValidate('positionId');
                });
            }

            this.postList = dept.childList;
        },
    }
};
</script>

<style scoped>
</style>