<template>
    <div class="frame-content">
        <div class="frame-content-wrap">
            <div class="filter">
                <div class="inline">
                    <el-input v-model="filters.name" placeholder="姓名" size="small"></el-input>
                </div>
                <div class="inline ml-8">
                    <el-input v-model="filters.tel" placeholder="联系电话" size="small"></el-input>
                </div>
                <div class="inline date-picker date-picker-small ml-8">
                    <el-date-picker
                        v-model="dateRange"
                        type="daterange"
                        range-separator="至"
                        start-placeholder="开始日期"
                        end-placeholder="结束日期"
                        value-format="yyyy-MM-dd"
                        size="small">
                    </el-date-picker>
                </div>
                <div class="inline ml-8">
                    <el-button type="primary" size="small" icon="el-icon-search" @click="loadTable">查询</el-button>
                </div>
            </div>
            <div class="datatable-wrap mt-10">
                <div class="topbar">
                    <div class="inline">
                        <el-button type="primary" size="small" @click="handleAdd">新增</el-button>
                    </div>
                    <div class="inline ml-8">
                        <el-button type="danger" size="small" @click="handleDeleteAll">删除</el-button>
                    </div>
                    <div class="inline ml-8">
                        <el-button type="success" size="small" @click="handleDisable(0)">启用</el-button>
                    </div>
                    <div class="inline ml-8">
                        <el-button type="warning" size="small" @click="handleDisable(1)">禁用</el-button>
                    </div>
                </div>
                <div class="datatable">
                    <el-table
                        ref="currentTable"
                        :data="tableData"
                        v-loading="tableLoading"
                        element-loading-background="rgba(255, 255, 255, 0.3)"
                        border
                        style="width: 100%">
                        <el-table-column
                            type="selection"
                            width="55"
                            align="center">
                        </el-table-column>
                        <el-table-column
                            label="序号"
                            align="center"
                            width="60">
                            <template slot-scope="scope">
                                <span>{{ (pageNum - 1) * pageSize + scope.$index + 1}}</span>
                            </template>
                        </el-table-column>
                        <el-table-column
                            prop="orgName"
                            label="所属组织"
                            align="left"
                            width="180">
                        </el-table-column>
                        <el-table-column
                            prop="userNo"
                            label="工号">
                        </el-table-column>
                        <el-table-column
                            prop="userName"
                            label="姓名">
                        </el-table-column>
                        <el-table-column
                            prop="deptName"
                            label="部门">
                        </el-table-column>
                        <el-table-column
                            prop="positionName"
                            label="岗位">
                        </el-table-column>
                        <el-table-column
                            prop="tel"
                            align="left"
                            width="130"
                            label="联系电话">
                        </el-table-column>
                        <el-table-column
                            prop="enabled"
                            label="状态"
                            align="center"
                            width="80">
                            <template slot-scope="scope">
                                <el-tag v-if="scope.row.enabled == 0" type="primary" disable-transitions>启用</el-tag>
                                <el-tag v-else type="danger" disable-transitions>禁用</el-tag>
                            </template>
                        </el-table-column>
                        <el-table-column
                            prop="lastLoginTime"
                            label="最近登录时间"
                            align="center"
                            width="150">
                        </el-table-column>
                        <el-table-column
                            prop="created"
                            label="创建时间"
                            align="center"
                            width="150">
                        </el-table-column>
                        <el-table-column
                            prop="action"
                            label="操作"
                            align="center"
                            width="120">
                            <template slot-scope="scope">
                                <el-button
                                    size="mini"
                                    type="warning"
                                    @click="handleEdit(scope.$index, scope.row)">修改</el-button>
                            </template>
                        </el-table-column>
                    </el-table>
                </div>
                <div class="pagination">
                    <el-pagination
                        @size-change="handleSizeChange"
                        @current-change="handleCurrentChange"
                        :background="true"
                        :current-page="pageNum"
                        :page-sizes="[50, 100, 200, 300, 400, 500]"
                        :page-size="pageSize"
                        layout="total, sizes, prev, pager, next, jumper"
                        :total="totalNum">
                    </el-pagination>
                </div>
            </div>

            <el-dialog
                title="添加"
                v-if="addDialog.create"
                :visible.sync="addDialog.visible"
                :close-on-click-modal="false"
                @closed="handleAddClosed"
                class="custom-dialog small-dialog"><!-- big-dialog small-dialog mini-dialog  -->
                <UserAdd @done="handleAddDone"></UserAdd>
            </el-dialog>
            
            <el-dialog
                title="编辑"
                v-if="editDialog.create"
                :visible.sync="editDialog.visible"
                :close-on-click-modal="false"
                @closed="handleEditClosed"
                class="custom-dialog small-dialog"><!-- big-dialog small-dialog mini-dialog  -->
                <UserEdit :params="editDialog.params" @done="handleEditDone"></UserEdit>
            </el-dialog>
        </div>
    </div>
</template>

<script>
import common from '@/utils/common'
import http from '@/utils/http'
import UserAdd from './UserAdd'
import UserEdit from './UserEdit'

export default {
    name: 'SystemUser',
    components: {
        UserAdd,
        UserEdit
    },
    data() {
        return {
            filters: {
                name: '',
                tel: '',
                startTime: '',
                endTime: '',
            },

            dateRange: [],

            tableData: [],

            totalNum: 0,
            pageNum: 1,
            pageSize: 50,

            tableLoading: false,

            addDialog: {
                create: false,
                visible: false,
            },

            editDialog: {
                create: false,
                visible: false,
                params: {},
            },
        };
    },
    created () {
        this.loadTable();
    },
    watch: {
        ['dateRange'] (val) {
            if (!val || val.length < 1) {
                this.filters.startTime = '';
                this.filters.endTime = '';
                return;
            }
            this.filters.startTime = val[0];
            this.filters.endTime = val[1];
        }
    },
    methods: {
        ...common,
        // 加载表格数据
        loadTable() {
            let {
                name,
                tel,
                startTime,
                endTime,
            } = this.filters;

            let params = {};

            if (name != '') {
                params.name = name;
            }

            if (tel != '') {
                params.tel = tel;
            }

            if (startTime != '' && endTime != '') {
                params.startTime = startTime;
                params.endTime = endTime;
            }

            params.pageNum = this.pageNum;
            params.pageSize = this.pageSize;

            this.tableLoading = true;
            http.get('v1/user/listPage', {
                params: params
            }).then(req => {
                this.tableLoading = false;
                if (this.httpThen(req)) {
                    return;
                }
                if (req.data.code == http.SUCCESS) {
                    let data = req.data.data.records;
                    data.forEach(item => {
                        item.created = item.created.substr(0, 16);
                    });
                    this.tableData = data;
                    this.totalNum = req.data.data.total;
                } else {
                    this.showError(req.data.msg);
                }
            }).catch(err => {
                this.tableLoading = false;
                this.httpCatch(err);
            });
        },
        handleSizeChange(pageSize) {
            this.pageSize = pageSize;
            this.loadTable();
        },
        handleCurrentChange(pageNum) {
            this.pageNum = pageNum;
            this.loadTable();
        },

        // 详情弹窗口
        handleDetail(index, row) {
            this.detailDialog = { create: true, visible: false, params: row };
            this.$nextTick(() => {
                this.detailDialog = { create: true, visible: true, params: row };
            });
        },
        handleDetailClosed() {
            this.detailDialog = { create: false, visible: false, params: {} };
        },

        // 添加弹窗口
        handleAdd() {
            this.addDialog = { create: true, visible: false };
            this.$nextTick(() => {
                this.addDialog = { create: true, visible: true };
            });
        },
        handleAddDone() {
            this.addDialog = { create: true, visible: false };
            this.loadTable();
        },
        handleAddClosed() {
            this.addDialog = { create: false, visible: false };
        },

        // 编辑弹窗口
        handleEdit(index, row) {
            this.editDialog = { create: true, visible: false, params: row };
            this.$nextTick(() => {
                this.editDialog = { create: true, visible: true, params: row };
            });
        },
        handleEditDone() {
            this.editDialog = { create: true, visible: false, params: {} };
            this.loadTable();
        },
        handleEditClosed() {
            this.editDialog = { create: false, visible: false, params: {} };
        },

        handleDisable(status) {
            let text;
            if (status == 1) {
                text = '禁用'
            } else if (status == 0) {
                text = '启用'
            } else {
                this.showError('未知状态值！');
                return;
            }

            if (this.$refs.currentTable.selection.length == 0) {
                this.showError('请选择要'+text+'的信息！');
            } else {
                this.confirm('确认'+text+'所选信息？', function () {
                    let idarr = [];
                    for (let i = 0; i < this.$refs.currentTable.selection.length; i++) {
                        idarr.push(this.$refs.currentTable.selection[i].id);
                    }
                    http.post('v1/user/enable', { ids: idarr, enabled: status }).then(req => {
                        if (this.httpThen(req)) {
                            return;
                        }
                        if (req.data.code == http.SUCCESS) {
                            this.loadTable();
                            this.showSuccess(text+'成功！');
                        } else {
                            this.showError(req.data.msg);
                        }
                    });
                });
            }
        },

        handleDeleteAll() {
            if (this.$refs.currentTable.selection.length == 0) {
                this.showError('请选择要删除的信息！');
            } else {
                this.confirm('确认删除所选信息？', function () {
                    let idarr = [];
                    for (let i = 0; i < this.$refs.currentTable.selection.length; i++) {
                        idarr.push(this.$refs.currentTable.selection[i].id);
                    }
                    http.post('v1/user/del', { ids: idarr }).then(req => {
                        if (this.httpThen(req)) {
                            return;
                        }
                        if (req.data.code == http.SUCCESS) {
                            this.loadTable();
                            this.showSuccess('删除成功！');
                        } else {
                            this.showError(req.data.msg);
                        }
                    });
                });
            }
        },
    }
}
</script>

<style scoped>
</style>
