<template>
    <el-select
        ref="select"
        v-model="text"
        :placeholder="placeholder"
        :clearable="clearable"
        :multiple="multiple"
        style="wrapStyle"
        :size="size"
        class="select-tree select-hide-close"
        @clear="handleClear"
        @visible-change="handleVisibleChange"
        :filterable="filterable"
        :filter-method="filterMethod"
        :disabled="disabled"
        :popper-class="multiple ? 'select-tree-multiple-box' : 'select-tree-box'">
        <el-option hidden v-for="item in selectedData" :value="item[nodeKey]" :label="item[treeProps['label']]" :key="item[nodeKey]"></el-option>
        <el-option  
            value=""    
            style="height: auto">
            <el-tree 
                ref="tree"
                :node-key="nodeKey"
                :default-expand-all="defaultExpandAll"
                :expand-on-click-node="false"
                :data="data" 
                :show-checkbox="multiple"
                :check-strictly="checkStrictly"
                :props="treeProps" 
                :check-on-click-node="true"
                @check-change="handleCheckChange"
                :filter-node-method="localFilterNodeMethod"
                @node-click="handleNodeClick"></el-tree>
        </el-option>
    </el-select>
</template>

<script>
export default {
    name: 'SelectTree',
    model: {
        prop: 'value',
        event: 'change'
    },
    props: {
        placeholder: {
            type: String,
            default() {
                return '';
            },
        },
        value: {
            type: [String, Number, Array],
            default() {
                return '';
            },
        },
        data: {
            type: Array,
            default() {
                return [];
            },
        },
        multiple: {
            type: Boolean,
            default() {
                return false;
            },
        },
        checkStrictly: {
            type: Boolean,
            default() {
                return true;
            },
        },
        disabled: {
            type: Boolean,
            default() {
                return false;
            },
        },
        size: {
            type: String,
            default() {
                return '';
            },
        },
        defaultExpandAll: {
            type: Boolean,
            default() {
                return false;
            },
        },
        clearable: {
            type: Boolean,
            default() {
                return false;
            },
        },
        filterable: {
            type: Boolean,
            default() {
                return false;
            },
        },
        nodeKey: {
            type: String,
            default() {
                return 'id';
            },
        },
        treeProps: {
            type: Object,
            default() {
                return { 
                    children: 'children', 
                    label: 'label' 
                };
            },
        },
        filterNodeMethod: {
            type: Function,
            default: null,
        },
        showLevel: {
            type: Boolean,
            default() {
                return true;
            },
        },
        levelSeparator: {
            type: String,
            default() {
                return ' / ';
            },
        },
        selectMinLevel: {
            type: Number,
            default() {
                return -1;
            },
        }
    },
    data(){
        return {
            text: this.emptyValue(),
            selectedData: [],
            scrollTop: 0,
        }
    },
    created() {
        this.$nextTick(() => {
            this.selectData(this.value);
        });
        if (this.filterNodeMethod != null) {
            this.localFilterNodeMethod = this.filterNodeMethod;
        }
    },
    watch: {
        data () {
            this.$nextTick(() => {
                this.selectData(this.value);
            });
        },
        value (value) {
            if (value === this.text) {
                return;
            }
            this.$nextTick(() => {
                this.selectData(value);
            });
        }
    },
    methods: {
        emptyValue() {
            let text;
            if (this.multiple) {
                text = [];
            } else {
                text = '';
            }

            return text;
        },
        selectData(value, isChange = false) {
            if (this.multiple) {
                this.selectMultipleData(value, isChange);
                return;
            }
            
            this.$refs.tree.setCurrentKey(value);
            let node = this.$refs.tree.getNode(value);

            if (node) {
                this.text = this.getText(node);
                this.selectedData = [ node.data ];
            } else {
                this.handleClear(isChange);
            }
        },
        getText(node){
            if (!this.showLevel) {
                return node.data[this.treeProps.label];
            }
            
            let arr = [];
            let p = node;
            while(p && p.parent) {
                arr.push(p);
                p = p.parent;
            }
            let v = [];
            for (let i = arr.length - 1; i >= 0; i--) {
                v.push(arr[i].data[this.treeProps.label]);
            }
            return v.join(this.levelSeparator);
        },
        selectMultipleData(value, isChange = false) {
            this.checkClear(false);
            
            let arr = [];
            for (let i = 0; i < value.length; i++) {
                this.$refs.tree.setChecked(value[i], true);
                let node = this.$refs.tree.getNode(value);
                if (node) {
                    arr.push(node);
                }
            }

            this.selectedData = arr;
            this.text = value;

            if (isChange) {
                this.$emit('change', this.emptyValue());
            }
        },
        filterMethod(value) {
            this.$refs.tree.filter(value);
        },
        localFilterNodeMethod(value, data) {
            if (!value) return true;
            return data[this.treeProps.label].indexOf(value) !== -1;
        },
        handleClear(isChange = true) {
            if (this.multiple) {
                this.checkClear(isChange);
                return;
            }
            this.$refs.tree.setCurrentKey(null);
            this.$refs.select.blur();
            this.selectedData = [];
            this.text = this.emptyValue();
            if (isChange) {
                this.$emit('change', this.text);
            }
        },
        checkClear(isChange = true) {
            let nodes = this.$refs.tree.getCheckedNodes(false, true);
            for (let i = 0; i < nodes.length; i++) {
                this.$refs.tree.setChecked(nodes[i][this.nodeKey], false);
            }

            this.selectedData = [];
            this.text = this.emptyValue();

            if (isChange) {
                this.$emit('change', this.emptyValue());
            }
        },
        handleNodeClick(data) {
            if (this.multiple) {
                return;
            }

            let node = this.$refs.tree.getNode(data);
            if (node.level <= this.selectMinLevel) {
                node.expanded = !node.expanded;
                let key = null;
                if (this.selectedData.length > 0) {
                    key = this.selectedData[0];
                }
                this.$refs.tree.setCurrentKey(key);
                return;
            }

            this.text = this.getText(node);
            this.$refs.select.blur();
            this.selectedData = [ data ];
            this.$emit('change', data[this.nodeKey], data);
        },
        handleCheckChange() {
            if (this.multiple) {
                let nodes = this.$refs.tree.getCheckedNodes(false, true);
                let arr = [], keys = [];
                for (let i = 0; i < nodes.length; i++) {
                    arr.push(nodes[i]);
                    keys.push(nodes[i][this.nodeKey]);
                }
                this.selectedData = arr;
                this.text = keys;
                this.$emit('change', keys, arr);
            }
        },
        handleVisibleChange(visible) {
            if (visible) {
                if (this.filterable) {
                    this.filterMethod('');
                }

                let scrollbarWrap = this.findScrollbarWrap();

                if (scrollbarWrap) {
                    setTimeout(() => {
                        scrollbarWrap.scrollTop = this.scrollTop;
                    }, 50);
                }
            } else {
                let scrollbarWrap = this.findScrollbarWrap();

                if (scrollbarWrap) {
                    this.scrollTop = scrollbarWrap.scrollTop;
                }
            }
        },
        findScrollbarWrap() {
            return this.findParents(this.$refs.tree.$el, function (node) {
                if (node.className.indexOf('el-scrollbar__wrap') != -1) {
                    return true;
                }
            });
        },
        findParents(el, callback) {
            if (callback(el)) {
                return el;
            }

            if (el.parentNode) {
                return this.findParents(el.parentNode, callback);
            }
        },
    }
};
</script>

<style scoped>
</style>